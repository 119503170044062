@import "variables";

*,
*::before,
*::after {
  /*
  * bu tailwind kullanırken border ya da border-color kullandığımızda
  * border-style eklemek zorunda olmayalım diye eklendi.
  */
  border: 0 solid transparent;
}

@import "tailwindcss/utilities";

/* #region remove arrows from number input */
.no-arrow-input-number::-webkit-outer-spin-button,
.no-arrow-input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}
/* #endregion */

/* #region react-virtualized overrides */
.ReactVirtualized__Grid:focus,
.ReactVirtualized__List:focus {
  outline: none;
}
/* #endregion */

/* #region .ckeditor hacks */
.ck.ck-content {
  min-height: 250px !important;
}
/* #endregion */


/* #region .mceditor hacks */
.tox-notifications-container {display: none !important;}
.tox-tinymce {
  min-height: 500px !important;
}
/* #endregion */


.loader:empty {
  position: absolute;
  top: calc(50% - 3em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 0.75em solid rgba(0, 0, 0, 0.2);
  border-left: 0.75em solid $primary;
  border-radius: 50%;
  animation: load8 1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@import "material-overrides";
