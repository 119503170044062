body{
  font-size: 0.75rem !important;
}

.MuiInputBase-input {
  font-size: 0.75rem !important;
}

.MuiTypography-root {
  font-size: 0.75rem !important;
}

.MuiSwitch-root + .MuiTypography-body1 {
  font-size: 0.75rem !important;
}

.MuiToolbar-dense {
  min-height: 36px !important;
}

.MuiOutlinedInput-inputMarginDense{
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
}

.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense{
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
}

.MuiFormHelperText-root{
  margin: 0 !important;
}

.MuiCheckbox-root{
  padding: 3px !important;
  margin-left: -3px !important;
}

.MuiTooltip-tooltip{
  font-size: 1rem !important;
}
.MuiButton-text{
  padding: 3px !important;
  font-size: 0.75rem !important;
}